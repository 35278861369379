import * as React from 'react';
import {RoutePath} from "../../../utils/enums.js";
import {Link} from "react-router-dom";

export const HomePage = () => {
    return (
        <div id="wrap" className="boxed">
            <div className="grey-bg">
                <div>
                    <header id="nav"
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginLeft: '3rem',
                                marginRight: '3rem',
                            }}>
                        <div className="logo-2">
                            <img src="images/black-logo.png" className="logo-img" alt="Logo"/>
                        </div>
                        <Link to={RoutePath.SIGN_IN} sx={{color: 'black'}}>SIGN IN</Link>
                    </header>

                    <div className="sm-img-bg-fullscr parallax-section"
                         style={{
                             background: 'white',
                             marginBottom: '3rem',
                         }} data-stellar-background-ratio="0.5">
                        <div className="container sm-content-cont text-center" style={{
                            height: '90%'
                        }}>
                            <div className="sm-cont-middle">
                                <div className="opacity-scroll2">

                                    <div className="row" style={{
                                        width: '100%',
                                        margin: 'auto'
                                    }}>
                                        <div className="fes-img-main" style={{
                                            height: '320px'
                                        }}></div>
                                    </div>

                                    <div className="light-72-wide sm-mb-15 sm-mt-20">
                                        MEANINGFUL <span className="bold">CAREERS</span>
                                    </div>


                                    <div className="norm-16-wide sm-mb-50">
                                        At MC, we connect people with meaningful careers and businesses with the talent
                                        they
                                        need to grow.
                                    </div>


                                    <div className="center-0-478">
                                        <Link to={RoutePath.CANDIDATE_HOME} className="button medium thin hover-opacity"
                                           style={{
                                               background: '#5C0E7B',
                                               color: 'white',
                                               border: '2px solid #5C0E7B'
                                           }}>CANDIDATES</Link>
                                        <Link to={RoutePath.EMPLOYER_HOME}
                                              className="button medium thin ml-20 hover-opacity"
                                        style={{
                                            background: '#8B0E3A',
                                            color: 'white',
                                            border: '2px solid #8B0E3A'
                                        }}>EMPLOYERS</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};